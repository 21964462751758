var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    {
      staticClass: "toolbar-border-bottom",
      attrs: {
        color: "white",
        app: "",
        dense: "",
        "clipped-right": "",
        "clipped-left": !_vm.$route.params.sid,
        height: "48px",
        flat: "",
        "data-intro":
          "You can now directly move between organizations, spaces, instances and states using the dropdowns in the toolbar.",
        "data-step": "1"
      }
    },
    [
      !_vm.$route.params.sid
        ? _c(
            "div",
            { staticClass: "d-flex align-center" },
            [
              _c(
                "v-toolbar-title",
                { staticClass: "pl-0 flex-shrink-0" },
                [
                  _vm.$vuetify.breakpoint.smAndUp === true
                    ? _c(
                        "router-link",
                        {
                          staticStyle: { "text-decoration": "none" },
                          attrs: { to: { name: "home-welcome" } }
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "primary--text ma-0 pa-0",
                              staticStyle: { "min-width": "200px" }
                            },
                            [
                              _c("v-hover", {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var hover = ref.hover
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-center ml-1"
                                            },
                                            [
                                              _c("v-img", {
                                                staticClass: "ml-2 mr-1",
                                                style: hover
                                                  ? "filter: grayscale(100%)"
                                                  : "",
                                                attrs: {
                                                  contain: "",
                                                  "max-width": "110px",
                                                  "max-height": "23",
                                                  src: require("@/assets/Nuvolos-white.svg")
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  1602714463
                                )
                              })
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      !_vm.$route.params.sid
        ? _c("v-divider", {
            staticClass: "ml-3",
            attrs: { inset: "", vertical: "" }
          })
        : _vm._e(),
      _vm.$vuetify.breakpoint.mdAndUp
        ? _c("BreadcrumbsLargeScreen")
        : _c("BreadcrumbsSmallScreen"),
      _c("v-spacer"),
      _vm.$vuetify.breakpoint.mdAndUp && _vm.$auth.isAuthenticated()
        ? _c(
            "div",
            {
              staticClass:
                "d-flex align-center justify-space-around flex-shrink-0"
            },
            [
              _vm.userInfo && Object.keys(_vm.userInfo).length > 0
                ? _c(
                    "v-menu",
                    {
                      staticClass: "mt-1",
                      attrs: {
                        "close-on-content-click": true,
                        "nudge-width": 150,
                        "offset-y": "",
                        left: ""
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var menu = ref.on
                              return [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var tooltip = ref.on
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      small:
                                                        _vm.$route.name ===
                                                        "app-open",
                                                      text: ""
                                                    }
                                                  },
                                                  Object.assign(
                                                    {},
                                                    tooltip,
                                                    menu
                                                  )
                                                ),
                                                [
                                                  _vm.runningDeployments.length
                                                    ? _c(
                                                        "v-badge",
                                                        {
                                                          attrs: {
                                                            color: "secondary",
                                                            right: "",
                                                            overlap: ""
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "badge",
                                                                fn: function() {
                                                                  return [
                                                                    _c(
                                                                      "v-fab-transition",
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            attrs: {
                                                                              fab:
                                                                                ""
                                                                            },
                                                                            domProps: {
                                                                              textContent: _vm._s(
                                                                                _vm
                                                                                  .runningDeployments
                                                                                  .length
                                                                              )
                                                                            }
                                                                          }
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                },
                                                                proxy: true
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color: "primary"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "desktop_windows"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "primary"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "desktop_windows"
                                                          )
                                                        ]
                                                      )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [_c("span", [_vm._v("Running Apps")])]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3678299027
                      )
                    },
                    [
                      _c(
                        "v-list",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-list-item",
                            { attrs: { "two-line": "" } },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-list-item-title",
                                    {
                                      staticClass:
                                        "primary--text subtitle-1 mt-3 font-weight-bold"
                                    },
                                    [_vm._v("Running Applications")]
                                  ),
                                  _c("v-list-item-subtitle", [
                                    _vm._v(
                                      _vm._s(_vm.runningDeployments.length) +
                                        " applications found"
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.runningDeployments.length
                            ? _c("v-divider", { staticClass: "mt-3 mb-3" })
                            : _vm._e(),
                          _c(
                            "v-list-item-group",
                            { attrs: { color: "primary" } },
                            _vm._l(_vm.runningDeployments, function(app) {
                              return _c(
                                "v-list-item",
                                {
                                  key: app.aid,
                                  attrs: {
                                    to: {
                                      name: "app-open",
                                      params: {
                                        oid: app.oid,
                                        sid: app.sid,
                                        iid: app.iid,
                                        snid: app.snid,
                                        aid: app.aid
                                      }
                                    }
                                  }
                                },
                                [
                                  _c("v-list-item-avatar", [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.getAppLink(app.app_type)
                                      }
                                    })
                                  ]),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _c(
                                          "h4",
                                          { staticClass: "primary--text" },
                                          [_vm._v(_vm._s(app.name))]
                                        )
                                      ]),
                                      _c("v-list-item-subtitle", [
                                        _vm._v(_vm._s(app.description))
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-divider")
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-menu",
                {
                  staticClass: "mt-1",
                  attrs: {
                    "close-on-content-click": true,
                    "nudge-width": 150,
                    "offset-y": "",
                    left: ""
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var menu = ref.on
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var tooltip = ref.on
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              { attrs: { text: "" } },
                                              Object.assign({}, tooltip, menu)
                                            ),
                                            [
                                              _vm.runningUserTasks.length
                                                ? _c(
                                                    "v-badge",
                                                    {
                                                      attrs: {
                                                        color: "secondary",
                                                        right: "",
                                                        overlap: ""
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "badge",
                                                            fn: function() {
                                                              return [
                                                                _c(
                                                                  "v-fab-transition",
                                                                  [
                                                                    _c("span", {
                                                                      attrs: {
                                                                        fab: ""
                                                                      },
                                                                      domProps: {
                                                                        textContent: _vm._s(
                                                                          _vm
                                                                            .runningUserTasks
                                                                            .length
                                                                        )
                                                                      }
                                                                    })
                                                                  ]
                                                                )
                                                              ]
                                                            },
                                                            proxy: true
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "primary"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "notifications"
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: "primary"
                                                      }
                                                    },
                                                    [_vm._v("notifications")]
                                                  )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [_c("span", [_vm._v("Running tasks")])]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    4044820647
                  )
                },
                [
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item",
                        { attrs: { "two-line": "" } },
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                {
                                  staticClass:
                                    "primary--text subtitle-1 font-weight-bold"
                                },
                                [_vm._v("Running Tasks")]
                              ),
                              _c(
                                "v-list-item-subtitle",
                                { staticClass: "caption font-weight-medium" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.runningUserTasks.length) +
                                      " tasks found"
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.$route.name !== "user-tasks"
                        ? _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-list-item-title",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            exact: "",
                                            to: { name: "user-tasks" },
                                            small: "",
                                            outlined: "",
                                            text: ""
                                          }
                                        },
                                        [_vm._v("show all tasks")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-menu",
                {
                  attrs: { "offset-y": "", left: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var menu = ref.on
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var tooltip = ref.on
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  small:
                                                    _vm.$route.name ===
                                                    "app-open",
                                                  color: "primary",
                                                  text: ""
                                                }
                                              },
                                              Object.assign({}, tooltip, menu)
                                            ),
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-lifebuoy")
                                              ])
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [_c("span", [_vm._v("Help")])]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3866658255
                  )
                },
                [
                  _c(
                    "v-list",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.openDocs()
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-action",
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v("help_outline")
                              ])
                            ],
                            1
                          ),
                          _c("v-list-item-title", [_vm._v("Documentation")])
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.contactSupport()
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-action",
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v("mdi-lifebuoy")
                              ])
                            ],
                            1
                          ),
                          _c("v-list-item-title", [_vm._v("Ask for help")])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-menu",
                {
                  attrs: { "max-width": "200", "offset-y": "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var menu = ref.on
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var tooltip = ref.on
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  small:
                                                    _vm.$route.name ===
                                                    "app-open",
                                                  color: "primary",
                                                  text: ""
                                                }
                                              },
                                              Object.assign({}, tooltip, menu)
                                            ),
                                            [
                                              _c("v-icon", [
                                                _vm._v("account_circle")
                                              ])
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [_c("span", [_vm._v("Account")])]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    1304660217
                  )
                },
                [
                  _c(
                    "v-list",
                    { attrs: { nav: "", dense: "" } },
                    [
                      _c(
                        "v-list-item",
                        { attrs: { "two-line": "" } },
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                {
                                  staticClass:
                                    "primary--text subtitle-1 mt-3 font-weight-bold"
                                },
                                [_vm._v("Account & Settings")]
                              ),
                              _c("v-list-item-subtitle", [
                                _vm._v(
                                  _vm._s(
                                    _vm.userInfo ? _vm.userInfo.given_name : ""
                                  ) +
                                    " " +
                                    _vm._s(
                                      _vm.userInfo
                                        ? _vm.userInfo.family_name
                                        : ""
                                    )
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-divider", { staticClass: "mt-3 mb-3" }),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.openSettings()
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "subtitle-2 primary--text" },
                            [_vm._v("Settings")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.logout()
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "subtitle-2 primary--text" },
                            [_vm._v("Logout")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm.$auth.isAuthenticated()
        ? _c(
            "div",
            [
              _c(
                "v-menu",
                {
                  attrs: {
                    "offset-y": "",
                    "offset-overflow": "",
                    "close-on-content-click": false
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g({ attrs: { icon: "" } }, on),
                            [_c("v-icon", [_vm._v("menu")])],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c(
                    "v-expansion-panels",
                    { attrs: { accordion: "", focusable: "" } },
                    [
                      _c(
                        "v-expansion-panel",
                        [
                          _c(
                            "v-expansion-panel-header",
                            {
                              staticClass:
                                "caption primary--text font-weight-bold"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex justify-start" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-1",
                                      attrs: { small: "" }
                                    },
                                    [_vm._v("account_circle")]
                                  ),
                                  _vm._v(" Account & Settings ")
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c(
                                "v-list",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.openSettings()
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-title",
                                        {
                                          staticClass:
                                            "subtitle-2 primary--text d-flex align-center"
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-1",
                                              attrs: { small: "" }
                                            },
                                            [_vm._v("settings")]
                                          ),
                                          _vm._v("User Settings ")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.logout()
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-title",
                                        {
                                          staticClass:
                                            "subtitle-2 primary--text d-flex align-center"
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-1",
                                              attrs: { small: "" }
                                            },
                                            [_vm._v("exit_to_app")]
                                          ),
                                          _vm._v(" Logout ")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel",
                        [
                          _c(
                            "v-expansion-panel-header",
                            {
                              staticClass:
                                "caption primary--text font-weight-bold"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex justify-start" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-1",
                                      attrs: { small: "" }
                                    },
                                    [_vm._v("desktop_windows")]
                                  ),
                                  _vm._v(" Running Applications ")
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c(
                                "v-list-item-group",
                                { attrs: { color: "primary" } },
                                _vm._l(_vm.runningDeployments, function(app) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: app.aid,
                                      attrs: {
                                        to: {
                                          name: "app-open",
                                          params: {
                                            oid: app.oid,
                                            sid: app.sid,
                                            iid: app.iid,
                                            snid: app.snid,
                                            aid: app.aid
                                          }
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "subtitle-2 primary--text d-flex justify-start align-center"
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    width: "20",
                                                    height: "20",
                                                    src: _vm.getAppLink(
                                                      app.app_type
                                                    )
                                                  }
                                                }),
                                                _c(
                                                  "span",
                                                  { staticClass: "ml-1" },
                                                  [_vm._v(_vm._s(app.name))]
                                                )
                                              ]
                                            )
                                          ]),
                                          _c("v-list-item-subtitle", [
                                            _vm._v(_vm._s(app.description))
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel",
                        [
                          _c(
                            "v-expansion-panel-header",
                            {
                              staticClass:
                                "caption primary--text font-weight-bold"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex justify-start" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-1",
                                      attrs: { small: "" }
                                    },
                                    [_vm._v("mdi-lifebuoy")]
                                  ),
                                  _vm._v(" Help ")
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c(
                                "v-list",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.openDocs()
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-action",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("help_outline")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-list-item-title", [
                                        _vm._v("Documentation")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }